import { Component, OnInit, ViewChild, Inject, QueryList, ViewChildren } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { SelectionModel } from "@angular/cdk/collections";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyTabGroup as MatTabGroup } from "@angular/material/legacy-tabs";
import { DataAccessService } from "../core/services/data-access.service";
import { IFactory } from "../core/models/IFactory";
import { ISimpleListElement } from "../core/models/ISimpleListElement";
import Swal from "sweetalert2";
import { UploadService } from "../core/services/upload-service";
import { HttpResponse } from "@angular/common/http";
import { FrozenWeekComponent } from "./frozen-week.component";
import { MatLegacyCheckbox } from "@angular/material/legacy-checkbox";
import { MatLegacyRadioButton } from "@angular/material/legacy-radio";
import { IBizTeam } from "../core/models/IFactoryBizTeamPerCategory";

const initialSelection = [];
const allowMultiSelect = true;

const CATEGORYID_ALL: number = 0;

@Component({
  selector: "app-factories",
  templateUrl: "./factories.component.html",
  styleUrls: ["./factories.component.scss"],
})
export class FactoriesComponent implements OnInit {
  displayedColumns: string[] = [
    "select",
    "category_name",
    "category_others_name",
    "factory_no",
    "factory_name",
    "region",
    "bdm_user",
    "bd_user",
    "sp_user",
    "pne_user",
    "office_address",
    "pick_up_address",
    "unload_address",
    "invoice_address",
    "bank",
    "frozen_weeks",
    "last_update_dt",
    "last_update_user",
    "invalid_date",
  ];

  dataSource: MatTableDataSource<IFactory> = new MatTableDataSource<IFactory>();

  @ViewChild("pag1", { read: MatPaginator, static: true })
  paginator1: MatPaginator;
  @ViewChild("pag2", { read: MatPaginator, static: true })
  paginator2: MatPaginator;
  @ViewChild("table1", { read: MatSort, static: true }) sort1: MatSort;
  @ViewChild("table2", { read: MatSort, static: true }) sort2: MatSort;
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;

  selection = new SelectionModel<IFactory>(null, null);

  categoriesPure: ISimpleListElement[];
  categories: ISimpleListElement[];

  categoryId: number;
  currentSupplierCategoryId: number;
  currentCustomerCategoryId: number;
  allowEdit: boolean;
  allowAll: boolean;
  showInvalid: boolean = false;
  filterText: string = "";

  constructor(
    public dialog: MatDialog,
    private dataAccess: DataAccessService,
    private upload: UploadService
  ) {
    this.selection = new SelectionModel<IFactory>(
      allowMultiSelect,
      initialSelection
    );
    this.allowEdit = false;
    this.allowAll = false;
  }

  ngOnInit() {
    this.dataAccess
      .getJSONPost(
        "set_last_module",
        [],
        [{ k: "last_module", v: "factories" }]
      )
      .then(() => {});

    this.prepareCategoriesList();
  }

  supplierRole() {
    return (
      this.tabGroup.selectedIndex == 0 ||
      this.tabGroup.selectedIndex == undefined
    );
  }
  customerRole() {
    return this.tabGroup.selectedIndex == 1;
  }

  refreshList() {
    if (this.dataAccess.haveAccessToModule("factories")) {
      if (this.supplierRole()) {
        this.currentSupplierCategoryId = this.categoryId;
      } else if (this.customerRole()) {
        this.currentCustomerCategoryId = this.categoryId;
      }

      this.selection.clear();

      let is_supplier: string = this.supplierRole() ? "true" : null;
      let is_customer: string = this.customerRole() ? "true" : null;

      this.dataAccess
        .getJSONPost(
          "get_factories",
          [],
          [
            { k: "p_is_suppliers_tab", v: is_supplier },
            { k: "p_is_customers_tab", v: is_customer },
            { k: "p_category_id", v: this.categoryId },
            { k: "p_show_invalid", v: this.showInvalid },
          ]
        )
        .then((data) => {
          if (this.supplierRole()) {
            this.dataSource.paginator = this.paginator1;
            this.dataSource.sort = this.sort1;
          } else if (this.customerRole()) {
            this.dataSource.paginator = this.paginator2;
            this.dataSource.sort = this.sort2;
          }

          this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
            data[sortHeaderId]
              ? typeof data[sortHeaderId] === "string"
                ? data[sortHeaderId].toString().toLocaleLowerCase()
                : data[sortHeaderId]
              : null;
          this.dataSource.filter = this.filterText.trim().toLowerCase();
          this.dataSource.data = Array.isArray(data) ? data : [];

          this.dataAccess
            .getJSONPost(
              "check_factories_permissions",
              [],
              [
                { k: "p_category_id", v: this.categoryId },
                {
                  k: "p_is_customers_tab",
                  v: this.customerRole(),
                },
              ]
            )
            .then((data) => {
              this.allowAll = data.res;
              this.allowEdit = data.edit_own;
            });
        });
    } else {
      this.dataAccess.logout();
    }
  }

  prepareCategoriesList() {
    let _module: string = this.supplierRole() ? "suppliers" : "customers";

    this.dataAccess
      .getJSONPost("get_categories", [], [{ k: "p_module", v: _module }])
      .then((data) => {
        this.categories = this.categoriesPure = data || [];
      })
      .then(() => {
        if (this.categories.length == 1) {
          this.categoryId = this.categories[0].id;
        } else {
          this.categories = [{ id: CATEGORYID_ALL, name: "All" }, ...this.categories];
          if (this.supplierRole() && this.currentSupplierCategoryId >= 0) {
            this.categoryId = this.currentSupplierCategoryId;
          } else if (this.customerRole()) {
            if (this.currentCustomerCategoryId >= 0) {
              this.categoryId = this.currentCustomerCategoryId;
            } else {
              this.dataAccess.getJSON("get_user_category", [], []).then((data) => {
                if (data > 0) {
                  this.categoryId = data;
                }
              });
            }
          }
        }
      })
      .then(() => {
        this.refreshList();
        if (this.categories.findIndex(e => e.id == this.categoryId) < 0) {
          this.categoryId = CATEGORYID_ALL; // init to default category (all) only if not present in current categories
        }
    });
  }

  selectedTabChange() {
    this.filterText = "";
    this.dataSource = new MatTableDataSource([]);
    this.prepareCategoriesList();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // selection of all the rows on the current page
  getPageData() {
    return this.dataSource._pageData(
      this.dataSource._orderData(this.dataSource.filteredData)
    );
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle(checkboxChange: MatCheckboxChange) {
    this.isEntirePageSelected()
      ? this.selection.deselect(...this.getPageData())
      : this.selection.select(...this.getPageData());
  }

  openSetFrozenWeekDialog(): void {
    const dialogRef = this.dialog.open(FrozenWeekComponent, {
      data: this.selection.selected,
      width: "450px",
    });
    dialogRef.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }
  openAddDialog(): void {
    if (!this.categoryId || this.categoryId <= 0) {
      Swal.fire(
        "Error!",
        "To add new factory you have to select category first",
        "error"
      );
      return;
    }

    const dialogRef = this.dialog.open(EditFactoryDialog, {
      data: {
        selectedTabIndex: this.tabGroup.selectedIndex,
        categoryId: this.categoryId,
        editAll: this.allowAll,
      },
      width: "450px",
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }

  openEditDialog(pFactory: IFactory = null): void {
    if (this.allowEdit || this.allowAll) {
      let factoryToEdit: IFactory;

      if (pFactory) factoryToEdit = pFactory;
      else factoryToEdit = this.selection.selected[0];

      const dialogRef = this.dialog.open(EditFactoryDialog, {
        data: {
          selectedTabIndex: this.tabGroup.selectedIndex,
          factory: factoryToEdit,
          categoryId: this.categoryId,
          editAll: this.allowAll,
        },
        width: "450px",
      });

      dialogRef.afterClosed().subscribe(() => {
        this.refreshList();
      });
    }
  }

  openEditCategoriesDialog(): void {
    if (this.allowEdit || this.allowAll) {
      const factoryToEdit: IFactory = this.selection.selected[0];

      const dialogRef = this.dialog.open(EditFactoryCategoriesDialog, {
        data: {
          categories: this.categoriesPure,
          factory: factoryToEdit,
        },
        width: "450px",
      });

      dialogRef.afterClosed().subscribe(() => {
        this.refreshList();
      });
    }
  }

  delete(): void {
    Swal.fire({
      title: "Warning",
      text: "Are you sure you want set selected records as invalid?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        for (let s of this.selection.selected) {
          this.dataAccess
            .getJSONPost("remove_factory", [], [{ k: "p_id", v: s.id }])
            .then((data) => {
              if (data.res != 0) {
                Swal.fire("Error!", data.msg, "error");
              } else {
                this.refreshList();
              }
            });
        }
      }
    });
  }

  exportFailedRows(uploadId: number): void {
    window.open(
      this.dataAccess.getXLSUrl(
        "export_failed_rows",
        [],
        [{ k: "p_upload_id", v: uploadId }],
        "failed_to_upload"
      ),
      "_blank",
      "location=yes"
    );
  }

  export(): void {
    let is_supplier: number = this.supplierRole() ? 1 : 0;
    let is_customer: number = this.customerRole() ? 1 : 0;

    window.open(
      this.dataAccess.getXLSUrlNoJSON(
        "export_factories",
        [],
        [
          { k: "p_category_id", v: this.categoryId },
          { k: "p_is_suppliers_tab", v: is_supplier },
          { k: "p_is_customers_tab", v: is_customer },
          { k: "p_show_invalid", v: this.showInvalid },
        ],
        "factories"
      ),
      "_blank",
      "location=yes"
    );
  }

  showSelectFileDialog() {
    document.getElementById("fileUpload").click();
  }

  selectFile(event) {
    this.uploadFile(event.target.files);
    event.srcElement.value = null;
  }

  uploadFile(files: FileList) {
    if (files.length == 0) {
      return;
    }

    let file: File = files[0];
    let uploadURL;

    if ((this.dataAccess.getLoginData() as any).is_admin)
      uploadURL = this.dataAccess.getXLSUploadUrl(
        "migrate_factories",
        [],
        [{ k: "p_category_id", v: this.categoryId }]
      );
    else
      uploadURL = this.dataAccess.getXLSUploadUrl(
        "import_factories",
        [],
        [{ k: "p_category_id", v: this.categoryId }]
      );

    this.upload.uploadFile(uploadURL, file).subscribe(
      (event) => {
        if (event instanceof HttpResponse) {
          if (!((event.body as any).res == 0)) {
            //Swal.fire("Error!", (event.body as any).msg, 'error');
            let uploadId = (event.body as any).reference;
            Swal.fire({
              title: "Error!",
              html: (event.body as any).msg,
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Download failed",
              showCancelButton: true,
            }).then((result) => {
              if (result.value) {
                this.exportFailedRows(uploadId);
              }
            });
            this.refreshList();
          } else {
            Swal.fire("Import complete!", (event.body as any).msg, "success");
            this.refreshList();
          }
        }
      },
      (err) => {
        Swal.fire("Error!", "Upload Error: " + err.statusText, "error");
      },
      () => {}
    );
  }

  checkRow(row) {
    if (this.selection.isSelected(row)) {
      this.selection.deselect(row);
    } else {
      this.selection.select(row);
    }
  }
}

@Component({
  selector: "edit-factory-categories-dialog",
  templateUrl: "edit-factory-categories-dialog.html",
  styles: [
    ".mat-expansion-panel-header[aria-disabled=\"true\"] { color: unset; }",
    ".categories-table { width: 100%; }",
    ".cdk-column-Label { cursor: pointer; }",
    ".cdk-column-Checkbox, .cdk-column-IsMain { text-align: center; }",
  ]
})
export class EditFactoryCategoriesDialog implements OnInit {
  categories: ISimpleListElement[];
  categoriesStateInitial: { [id: number]: boolean };
  categoriesState: { [id: number]: boolean };
  categoryMainInitial: number;
  categoryMain: number;
  selectedCategory: number;
  selectedCategoryName: string;
  bizTeamInitial: IFactoryBizTeamIDsPerCategory;
  bizTeam: IFactoryBizTeamIDsPerCategory;
  usersBDM: { [id: number]: ISimpleListElement[] };
  usersBD: { [id: number]: ISimpleListElement[] };
  usersSP: { [id: number]: ISimpleListElement[] };
  usersPNE: { [id: number]: ISimpleListElement[] };

  factory: IFactory;

  @ViewChildren(MatLegacyCheckbox) checkboxes: QueryList<MatLegacyCheckbox>;
  @ViewChildren(MatLegacyRadioButton) radios: QueryList<MatLegacyRadioButton>;

  constructor(
    public dialogRef: MatDialogRef<EditFactoryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.categories = (data as any).categories;
    this.factory = (data as any).factory;
    this.categoriesStateInitial = this.categories.reduce(
      (result, category) => {
        result[category.id] = this.factory.categories.findIndex(cat => cat.cat_id == category.id) >= 0;
        return result;
      },
      {}
    );
    this.categoriesState = { ...this.categoriesStateInitial };
    this.categoryMainInitial = this.categoryMain = this.factory.category_id;
    this.selectedCategory = null;
    this.bizTeamInitial = Object.keys(this.factory.biz_teams).reduce( // deep clone
      (result, cat_id) => {
        const bizteamWithNames = this.factory.biz_teams[cat_id];
        const bizteam = Object.fromEntries(Object.entries(bizteamWithNames).filter(([key, _]) => ["bdm_user_id", "bd_user_id", "pne_user_id", "sp_user_id"].indexOf(key) >= 0));
        result[cat_id] = bizteam;
        return result;
      },
      {}
    );
    this.bizTeam = Object.fromEntries(Object.keys(this.bizTeamInitial).map(cat_id => { return [cat_id, { ...this.bizTeamInitial[cat_id] }]; }));
  }

  ngOnInit() {
    this.dataAccess
      .getJSONPost(
        "get_users_simple_list",
        [],
        [
          { k: "p_role", v: "bizteam" },
        ]
      )
      .then((data) => {
        const bizteamAllCategories = Array.isArray(data) ? data : [];

        if (bizteamAllCategories.length == 0) {
          Swal.fire(
            "Error!",
            "No available BDM/BD/SP/PNE users.",
            "error"
          );
          this.dialogRef.close();
          return;
        }

        this.usersBDM = this.usersForRole(bizteamAllCategories, 'BDM');
        this.usersBD = this.usersForRole(bizteamAllCategories, 'BD');
        this.usersSP = this.usersForRole(bizteamAllCategories, 'SP');
        this.usersPNE = this.usersForRole(bizteamAllCategories, 'PNE');
      });
  }

  usersForRole(bizteamAllCategories, bizteam_role) {
    return bizteamAllCategories.reduce(
      (result, user) => {
        const { category_id, role, ...userSimple } = user;
        if (bizteam_role == role)
          result[category_id] = [...(result[category_id] || []), userSimple];
        return result;
      },
      {}
    );
  }

  expandedCategories: boolean = true;
  expandedUsers: boolean = false;

  getSelectedCategoryName() {
    return this.categories.find(cat => cat.id == this.selectedCategory)?.name;
  }

  expandUsersPanel(id: number): void {
    this.selectedCategory = id;
    this.expandedCategories = false;
    this.expandedUsers = true;
  }

  switchCategory(id: number, enable: boolean): void {
    if (enable) {
      this.expandUsersPanel(id);
      if (!this.bizTeam[this.selectedCategory]) {
        this.bizTeam[this.selectedCategory] = { bdm_user_id: null, bd_user_id: null, pne_user_id: null, sp_user_id: null };
      }
    } else {
      this.selectedCategory = null;
      this.expandedCategories = true;
      this.expandedUsers = false;
    }
    const radioButton = this.radios.toArray().find(r => r.value == id);
    radioButton.disabled = !enable;
  }

  canDisableCategory(id: number): boolean {
    return id != this.categoryMain;
  }


  labelClicked(event, id: number): void {
    if (!this.canDisableCategory(id)) {
      this.expandUsersPanel(id);
    } else {
      this.categoriesState[id] = !this.categoriesState[id];
      this.switchCategory(id, this.categoriesState[id]);
    }
  }

  categoryChange(event, id: number): void {
    this.switchCategory(id, event.checked);
  }

  categoryClicked(event, id: number): void {
    if (!this.canDisableCategory(id)) {
      event.preventDefault();
    }
  }

  onSaveUsersClick(): void {
    const bizTeam = this.bizTeam[this.selectedCategory];
    if (bizTeam.bdm_user_id && bizTeam.bd_user_id && bizTeam.pne_user_id && bizTeam.sp_user_id) {
      this.selectedCategory = null;
      this.expandedCategories = true;
      this.expandedUsers = false;
    }
  }

  onCancelUsersClick(): void {
    if (this.canDisableCategory(this.selectedCategory)) {
      this.categoriesState[this.selectedCategory] = false;
    }
    this.selectedCategory = null;
    this.expandedCategories = true;
    this.expandedUsers = false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    const bizteamFiltered = this.bizteamFiltered();
    if (
      this.categoryMainInitial == this.categoryMain
      &&
      this.equalsDeep(this.categoriesStateInitial, this.categoriesState)
      &&
      this.equalsDeep(this.bizTeamInitial, bizteamFiltered)
    ) {
      this.dialogRef.close();
      return;
    }
    const catIdsSorted = Object.keys(this.categoriesState).map(v => Number.parseInt(v)).filter(catId => this.categoriesState[catId]).sort((a, b) => a - b);
    this.dataAccess
      .getJSONPost(
        "save_factory_bizteams",
        [],
        [
          { k: "p_id", v: this.factory.id },
          { k: "p_category_main_id", v: this.categoryMain },
          { k: "p_category_ids_arr", v: catIdsSorted },
          { k: "p_bdm_user_ids_arr", v: catIdsSorted.map(catId => bizteamFiltered[catId].bdm_user_id) },
          { k: "p_bd_user_ids_arr", v: catIdsSorted.map(catId => bizteamFiltered[catId].bd_user_id) },
          { k: "p_pne_user_ids_arr", v: catIdsSorted.map(catId => bizteamFiltered[catId].pne_user_id) },
          { k: "p_sp_user_ids_arr", v: catIdsSorted.map(catId => bizteamFiltered[catId].sp_user_id) },
        ]
      )
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          this.dialogRef.close();
        }
      });
  }

  bizteamFiltered(): IFactoryBizTeamIDsPerCategory {
    return Object.fromEntries(Object.entries(this.bizTeam).filter(([catId, bizteamCat]) => this.categoriesState[catId] && Object.values(bizteamCat).every(v => !!v)));
  }

  equalsDeep(a: any, b: any): boolean {
    return JSON.stringify(a, this.jsonReplacerSorter) === JSON.stringify(b, this.jsonReplacerSorter);
  }
  jsonReplacerSorter(key: any, val: any) {
    return (val instanceof Object && !(val instanceof Array)) ? Object.fromEntries(Object.entries(val).sort()) : val;
  }
}

interface IFactoryBizTeamIDsPerCategory {
  [cat_id: string]: IBizTeam;
}

@Component({
  selector: "edit-factory-dialog",
  templateUrl: "edit-factory-dialog.html",
})
export class EditFactoryDialog implements OnInit {
  bdm_users: ISimpleListElement[];
  bd_users: ISimpleListElement[];
  sp_users: ISimpleListElement[];
  pne_users: ISimpleListElement[];

  categoryId: number;
  selectedTabIndex: number;
  factory: IFactory;
  editAll: boolean = false;
  minDate: Date;

  constructor(
    public dialogRef: MatDialogRef<EditFactoryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.selectedTabIndex = (data as any).selectedTabIndex;
    this.categoryId = (data as any).categoryId;
    this.factory = (data as any).factory
      ? (data as any).factory
      : (new Object() as IFactory);
    this.editAll = (data as any).editAll;
    this.minDate = new Date();
  }

  ngOnInit() {
    if (this.isSupplier()) {
      this.factory.is_supplier = true;
    } else if (this.isCustomer()) {
      this.factory.is_customer = true;
    }

    this.dataAccess
      .getJSONPost(
        "get_users_simple_list",
        [],
        [
          { k: "p_role", v: "bdm" },
          { k: "p_category_id", v: this.categoryId },
        ]
      )
      .then((data) => {
        this.bdm_users = Array.isArray(data) ? data : [];

        if (this.bdm_users.length == 0) {
          Swal.fire(
            "Error!",
            "No available BDM users for this category.",
            "error"
          );
          this.dialogRef.close();
          return;
        }
      });

    this.dataAccess
      .getJSONPost(
        "get_users_simple_list",
        [],
        [
          { k: "p_role", v: "bd" },
          { k: "p_category_id", v: this.categoryId },
        ]
      )
      .then((data) => {
        this.bd_users = Array.isArray(data) ? data : [];

        if (this.bd_users.length == 0) {
          Swal.fire(
            "Error!",
            "No available BD users for this category.",
            "error"
          );
          this.dialogRef.close();
          return;
        }
      });

    this.dataAccess
      .getJSONPost(
        "get_users_simple_list",
        [],
        [
          { k: "p_role", v: "sp" },
          { k: "p_category_id", v: this.categoryId },
        ]
      )
      .then((data) => {
        this.sp_users = Array.isArray(data) ? data : [];

        if (this.sp_users.length == 0) {
          Swal.fire(
            "Error!",
            "No available SP users for this category.",
            "error"
          );
          this.dialogRef.close();
          return;
        }
      });

    this.dataAccess
      .getJSONPost(
        "get_users_simple_list",
        [],
        [
          { k: "p_role", v: "pne" },
          { k: "p_category_id", v: this.categoryId },
        ]
      )
      .then((data) => {
        this.pne_users = Array.isArray(data) ? data : [];

        if (this.pne_users.length == 0) {
          Swal.fire(
            "Error!",
            "No available PNE users for this category.",
            "error"
          );
          this.dialogRef.close();
          return;
        }
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    // if (! /^\d{5}$/.test(this.factory.factory_no)) {
    //   Swal.fire("Error!", 'Factory No. format is incorrect. Use 5-digit number.', 'error');
    //   return;
    // }

    this.dataAccess
      .getJSONPost(
        "save_factory",
        [],
        [
          { k: "p_id", v: this.factory.id },
          { k: "p_is_supplier", v: this.factory.is_supplier },
          { k: "p_is_customer", v: this.factory.is_customer },
          { k: "p_category_id", v: this.categoryId },
          { k: "p_factory_no", v: this.factory.factory_no },
          { k: "p_factory_name", v: this.factory.factory_name },
          { k: "p_bank", v: this.factory.bank },
          { k: "p_region", v: this.factory.region },
          { k: "p_bdm_user_id", v: this.factory.bdm_user_id },
          { k: "p_bd_user_id", v: this.factory.bd_user_id },
          { k: "p_pne_user_id", v: this.factory.pne_user_id },
          { k: "p_sp_user_id", v: this.factory.sp_user_id },
          { k: "p_office_address", v: this.factory.office_address },
          { k: "p_pick_up_address", v: this.factory.pick_up_address },
          { k: "p_unload_address", v: this.factory.unload_address },
          { k: "p_invoice_address", v: this.factory.invoice_address },
          {
            k: "p_invalid_date",
            v: this.dataAccess.parseDate(this.factory.invalid_date),
          },
        ]
      )
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          this.dialogRef.close();
        }
      });
  }

  isSupplier() {
    return this.selectedTabIndex == 0;
  }
  isCustomer() {
    return this.selectedTabIndex == 1;
  }
}
