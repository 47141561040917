import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent, FilterDialog } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import {
  NativeDateModule,
  MAT_DATE_FORMATS,
  DateAdapter,
} from "@angular/material/core";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatSidenavModule } from "@angular/material/sidenav";
import { HttpClientModule, HttpClientXsrfModule } from "@angular/common/http";
import { UsersComponent, EditUserDialog, ChangeCategoryUserDialog} from "./users/users.component";
import { HomeComponent } from "./home/home.component";
import {
  ProductsComponent,
  EditItemDialog,
  EditProductTypeDialog,
  EditProductNameDialog,
  EditProductLeadTimeDialog,
} from "./products/products.component";
import {
  FactoriesComponent,
  EditFactoryDialog,
  EditFactoryCategoriesDialog
} from "./factories/factories.component";
import {
  ParametersComponent,
  EditGFARDialog,
  EditClaimReasonCodeDialog,
} from "./parameters/parameters.component";
import {
  AgreementsComponent,
  EditAgreementDialog,
} from "./agreements/agreements.component";
import {
  OrdersComponent,
  SaveSearchDialog,
  ProceedDialog,
} from "./orders/orders.component";
import {
  KpireportsComponent,
  AddSeriesDialog,
  DeleteSeriesDialog,
} from "./kpireports/kpireports.component";
import { ConfigmodulesComponent } from "./configmodules/configmodules.component";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import {
  MatLegacyPaginator as MatPaginator,
  MatLegacyPaginatorModule as MatPaginatorModule,
} from "@angular/material/legacy-paginator";
import { MatSort, MatSortModule } from "@angular/material/sort";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgChartsModule } from "ng2-charts";
import { MatBadgeModule } from "@angular/material/badge";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { MatLegacyProgressBarModule as MatProgressBarModule } from "@angular/material/legacy-progress-bar";

import { DataAccessService } from "./core/services/data-access.service";
import { LoginComponent, GDPRDialog } from "./login/login.component";
import { UploadService } from "./core/services/upload-service";
import { MatrixComponent, EditMatrixDialog } from "./matrix/matrix.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ColorPickerComponent } from "./core/components/color-picker/color-picker.component";
import { OrderlinesComponent } from "./orderlines/orderlines.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { LfSelectSearchComponent } from "./core/components/lf-select-search/lf-select-search.component";
import { LfMultiselectSearchComponent } from "./core/components/lf-multiselect-search/lf-multiselect-search.component";
import { SettingsComponent } from "./settings/settings.component";
import { OrderHistoryDialog } from "./orders/history";
import { ForgotPasswordComponent } from "./forgotpassword/forgotpassword.component";
import { PasswordresetComponent } from "./passwordreset/passwordreset.component";
import {
  ClaimsComponent,
  SaveClaimSearchDialog,
} from "./claims/claims.component";
import { ClaimEditComponent } from "./claims/claim_edit";
import { ProceedClaimDialog } from "./claims/proceed_claim_dialog";
import { ClaimHistoryDialog } from "./claims/history";
import { AttachmentDialog } from "./claims/attachment_dialog";
import {
  ForecastsComponent,
  EditForecastDialog,
} from "./forecasts/forecasts.component";
import {
  WarningsComponent,
  WarningsSettingsDialog,
} from "./warnings/warnings.component";
import { CookieService } from "ngx-cookie-service";

import { AppDateAdapter, APP_DATE_FORMATS } from "./format-datepicker";
import { HelpComponent } from "./help/help.component";
import { SelectCheckAllComponent } from "./core/components/select-check-all/select-check-all.component";

import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { LoaderService } from "./core/services/loader.service";
import { LoaderInterceptor } from "./core/interceptors/loader-interceptor.service";
import { MyLoaderComponent } from "./core/components/my-loader/my-loader.component";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { PreventDoubleClickDirective } from "./core/directives/throttle.directive";
import { ReleasenotesComponent } from "./releasenotes/releasenotes.component";
import { FaqComponent } from "./faq/faq.component";
import {
  CalendarComponent,
  EditCalendarDialog,
  DeleteCalendarSeriesDialog,
} from "./calendar/calendar.component";
import { CommunicationComponent } from "./communication/communication.component";
import { ConversationComponent } from "./communication/conversation.component";
import { HighlightSearchPipe } from "./highlighttable-search.pipe";
import { WarningsService } from "./core/services/warnings.service";
import { FrozenWeekComponent } from "./factories/frozen-week.component";

@NgModule({
  declarations: [
    AppComponent,
    UsersComponent,
    HomeComponent,
    ProductsComponent,
    FactoriesComponent,
    ParametersComponent,
    AgreementsComponent,
    OrdersComponent,
    KpireportsComponent,
    ConfigmodulesComponent,
    EditUserDialog,
	ChangeCategoryUserDialog,
    EditProductTypeDialog,
    EditProductNameDialog,
    EditItemDialog,
    EditProductLeadTimeDialog,
    EditFactoryDialog,
    EditFactoryCategoriesDialog,
    EditAgreementDialog,
    EditMatrixDialog,
    EditGFARDialog,
    EditClaimReasonCodeDialog,
    LoginComponent,
    MatrixComponent,
    SaveSearchDialog,
    SaveClaimSearchDialog,
    ColorPickerComponent,
    OrderlinesComponent,
    ProceedDialog,
    LfSelectSearchComponent,
    LfMultiselectSearchComponent,
    SettingsComponent,
    OrderHistoryDialog,
    ForgotPasswordComponent,
    PasswordresetComponent,
    FilterDialog,
    ClaimsComponent,
    ClaimEditComponent,
    ProceedClaimDialog,
    ClaimHistoryDialog,
    AttachmentDialog,
    ForecastsComponent,
    EditForecastDialog,
    EditCalendarDialog,
    WarningsComponent,
    WarningsSettingsDialog,
    HelpComponent,
    AddSeriesDialog,
    DeleteSeriesDialog,
    SelectCheckAllComponent,
    GDPRDialog,
    MyLoaderComponent,
    PreventDoubleClickDirective,
    ReleasenotesComponent,
    FaqComponent,
    CalendarComponent,
    DeleteCalendarSeriesDialog,
    CommunicationComponent,
    ConversationComponent,
    HighlightSearchPipe,
    FrozenWeekComponent,
  ],
  imports: [
    DragDropModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    HttpClientModule,
    HttpClientXsrfModule,
    MatListModule,
    MatCardModule,
    MatGridListModule,
    MatTabsModule,
    MatTableModule,
    MatSelectModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    MatBadgeModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatExpansionModule,
    MatRadioModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    NgxMatSelectSearchModule,
    NativeDateModule,
  ],
  providers: [
    WarningsService,
    DataAccessService,
    UploadService,
    CookieService,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
