import {
  Component,
  Sanitizer,
  ViewChild,
  QueryList,
  ViewChildren,
  Inject,
} from "@angular/core";
import { MatExpansionPanel } from "@angular/material/expansion";
import { MatIconRegistry } from "@angular/material/icon";
import { MatLegacyMenuTrigger as MatMenuTrigger } from "@angular/material/legacy-menu";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { DataAccessService } from "./core/services/data-access.service";
import { Router } from "@angular/router";
import { timer } from "rxjs";
import { IFilter } from "./core/models/IFilter";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import Swal from "sweetalert2";
import { CookieService } from "ngx-cookie-service";
import { ISimpleListElement } from "./core/models/ISimpleListElement";
import { IEarlyWarning } from "./core/models/IEarlyWarning";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [DataAccessService],
  viewProviders: [MatExpansionPanel],
})
export class AppComponent {
  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;

  title = "icom";
  showLeftCaptions;
  showRightCaptions;
  roles = null;
  roleid;
  filtersHomePage: IFilter[] = [];
  filtersRightPanel: IFilter[] = [];
  forceChange: boolean;
  login: string;
  earlyWarningsCount: number;
  db: string = "";

  constructor(
    private router: Router,
    iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private dataAccess: DataAccessService,
    public dialog: MatDialog,
    private cookieService: CookieService,
    public http: HttpClient
  ) {
    this.dataAccess.getCsrf();

    if (localStorage.getItem("appKey")) {
      this.dataAccess
        .getJSONPost("session_check", [], [])
        .then((data) => {
          if (data.last_module !== "") {

            this.roleid = Number(this.cookieService.get("roleid"));
            data.user_data.user_role_id = this.roleid;

            document.getElementById("config_data_tmp").innerHTML =
              JSON.stringify(data.user_data);

            var last_module = data.last_module;
            if (
              last_module == null ||
              last_module == undefined ||
              last_module == ""
            )
              last_module = "home";
            this.router.navigate(["/" + last_module]);
          } else {
            this.router.navigate(["/login"]);
          }
        })
        .catch((data) => {
          this.router.navigate(["/login"]);
        });
    }
    iconRegistry.addSvgIcon(
      "orders",
      sanitizer.bypassSecurityTrustResourceUrl("assets/img/orders.svg")
    );
    iconRegistry.addSvgIcon(
      "claims",
      sanitizer.bypassSecurityTrustResourceUrl("assets/img/claims.svg")
    );
    iconRegistry.addSvgIcon(
      "forecast",
      sanitizer.bypassSecurityTrustResourceUrl("assets/img/forecast.svg")
    );
    iconRegistry.addSvgIcon(
      "settings",
      sanitizer.bypassSecurityTrustResourceUrl("assets/img/settings.svg")
    );

    this.showLeftCaptions = this.cookieService.get("leftPanel") == "true";
    this.showRightCaptions = this.cookieService.get("rightPanel") == "true";

    let baseUrl = window.location.origin;
    if (baseUrl.includes("itos-test-lf")) this.db = "test";
    else if (baseUrl.includes("itos-test")) this.db = "edu";
  }

  ngOnInit() {
    let version = document.getElementsByTagName("app-root");
    version[0].attributes.removeNamedItem("ng-version");
    if (window.location.href) {
      if (window.location.href.search("passwordreset") > -1) {
        return;
      }
    }

    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > -1 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return error
      alert(
        "An unsupported browser has been detected. Use another browser to be able to use GAMS."
      );
      return false;
    }

    // const timersrc = timer(1000, 5 * 60 * 1000);
    // timersrc.subscribe(value => {
    //   this.dataAccess.getJSON("session_check", [], []);
    // });

    const timerSidebar = timer(0, 5 * 60 * 1000 + 3000);
    timerSidebar.subscribe((value) => {
      //console.log("app " + new Date().toLocaleTimeString());
      if (
        !(
          Object.keys(this.dataAccess.getLoginData()).length === 0 &&
          this.dataAccess.getLoginData().constructor === Object
        )
      ) {
        this.loadFilters();
        this.countWarnings();
      }
    });
  }

  isUserLogged() {
    let res: boolean = (this.dataAccess.getLoginData() as any).user_id > 0;
    if (res && this.roles == null) {
      this.roles = (this.dataAccess.getLoginData() as any).roles;
      this.login = (this.dataAccess.getLoginData() as any).user_login;
    }
    return res;
  }

  showSidePanels() {
    return (
      this.isUserLogged() &&
      !(this.dataAccess.getLoginData() as any).password_change_required
    );
  }

  changeLeftPanel() {
    this.showLeftCaptions = !this.showLeftCaptions;
    this.cookieService.set("leftPanel", this.showLeftCaptions.toString());
  }

  changeRightPanel() {
    this.showRightCaptions = !this.showRightCaptions;
    this.cookieService.set("rightPanel", this.showRightCaptions.toString());
  }

  public loadFilters(forceCount: boolean = false) {
    if ((this.dataAccess.getLoginData() as any).user_id) {
      this.dataAccess
        .getJSONPost(
          "get_filters",
          [],
          [
            { k: "p_area", v: "right_menu" },
            { k: "p_force_count", v: forceCount },
          ]
        )
        .then((data) => {
          this.filtersRightPanel = Array.isArray(data) ? data : [];
        });
    }
  }

  public countWarnings() {
    this.dataAccess.getJSON("get_early_warnings_count", [], []).then((data) => {
      this.earlyWarningsCount = data[0].count;
    });
  }

  saveToLog(errorMessage: string, content: string, url: string) {
    this.dataAccess.getJSONPost(
      "log",
      [],
      [
        { k: "p_error_msg", v: errorMessage },
        // { k: "p_user_id", v: xxx }, // added automatically
        { k: "p_url", v: 'angular://' + url },
        { k: "p_content", v: content },
        // { k: "p_http_method", v: xxx },
        // { k: "p_headers", v: xxx },
        // { k: "p_client_ip", v: xxx },
        // { k: "p_exception", v: xxx },
        // { k: "p_query", v: xxx },
      ]
    );
  }

  getSelectedUserRoleId(): number {
    let res: number = 0;
    if ((this.dataAccess.getLoginData() as any).user_role_id > 0) {
      res = (this.dataAccess.getLoginData() as any).user_role_id;
    }
    return res;
  }

  setUserRole(roleId: number) {
    this.cookieService.set("roleid", roleId.toString());

    let data = this.dataAccess.getLoginData();
    (data as any).user_role_id = roleId;
    this.loadFilters();
    this.countWarnings();
    this.dataAccess.setLoginData(data);
    //this.router.navigate(['/home']);
    this.redirectTo("/home");
  }

  // this function will redirect to a dummy route and quickly return to the destination route without the user realizing it
  redirectTo(uri: string) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  haveAccessToModule(module: string): boolean {
    return this.dataAccess.haveAccessToModule(module);
  }

  changePassword() {
    this.router.navigate(["/settings"]);
  }

  logout() {
    this.dataAccess.getJSON("logout", [], []).then((data) => {});
    this.dataAccess.setLoginData({});
    localStorage.removeItem("appKey");
    this.roles = null;
    this.router.navigate(["/login"]);
    this.dataAccess.getCsrf();
  }

  styleBadge(filterId: number, filterColor: string) {
    if (
      document.querySelectorAll(
        "#filterIcon_" + filterId + " .mat-badge-content"
      )[0]
    ) {
      (
        document.querySelectorAll(
          "#filterIcon_" + filterId + " .mat-badge-content"
        )[0] as HTMLElement
      ).style.backgroundColor = filterColor;
    }
  }

  filterClick(e, id: number) {
    e.preventDefault();

    let dialogRef = this.dialog.open(FilterDialog, {
      data: { id: id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadFilters();
    });
  }

  showMaintenanceWarning() {
    return Date.now() < new Date("2025-03-19T15:45:00Z").getTime(); // Date.now is UTC, Date parse with Z at the end + getTime() is UTC
  }

  getMaintenanceWarning() {
    return "On <b>" + this.getMaintenanceWindow() + "</b> we will update GAMS - system access will not be possible in this time window.";
  }

  getMaintenanceWindow() {
    return "March 19th between 15:00 and 16:00 GMT";
  }

  isMaintenance() {
    const isBypassed = false; // localStorage?.getItem("maintenance_bypass") === 'W6*vp*w33HYNjd+h';
    const now = Date.now(); // UTC
    return !isBypassed && now >= new Date("2025-03-19T15:00:00Z").getTime() && now < new Date("2025-03-19T15:45:00Z").getTime(); // Date parse with Z at the end + getTime() is UTC
  }
}

@Component({
  selector: "delete-filter-dialog",
  templateUrl: "delete-filter-dialog.html",
})
export class FilterDialog {
  id: number;

  constructor(
    public dialogRef: MatDialogRef<FilterDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {
    this.id = (data as any).id;
  }

  deleteFilter() {
    this.dataAccess
      .getJSONPost("remove_filter", [], [{ k: "p_id", v: this.id }])
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          Swal.fire({
            icon: "success",
            title: "Filter deleted!",
            showConfirmButton: false,
            timer: 1500,
            willClose: () => {
              this.dialogRef.close();
            },
          });
        }
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
