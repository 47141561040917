<ng-container *ngIf="app.showMaintenanceWarning() && !app.isMaintenance()">
  <mat-card class="login-card mat-card-standalone" style="margin-bottom:-7.5%;">
    <mat-card-content>
      <mat-card-header>
        <mat-card-title class="mat-card-title">ANNOUNCEMENT</mat-card-title>
        <mat-card-subtitle [innerHTML]="app.getMaintenanceWarning()"></mat-card-subtitle>
      </mat-card-header>
    </mat-card-content>
  </mat-card>
</ng-container>

<mat-card class="login-card mat-card-standalone">
  <ng-container *ngIf="app.isMaintenance()">
    <mat-card-header>
      <mat-card-title>GAMS is unavailable now</mat-card-title>
      <mat-card-subtitle>Please try again later. Maintenance window is {{ app.getMaintenanceWindow() }}</mat-card-subtitle>
    </mat-card-header>
  </ng-container>


  <mat-card-content *ngIf="!app.isMaintenance()">
    <form class="login-form">
      <table class="full-width" cellspacing="0">
        <tr>
          <td align="center">
            <p class="mat-card-title">Login</p>
          </td>
        </tr>
        <tr>
          <td align="center">
            <i class="material-icons md-64">account_circle</i>
          </td>
        </tr>
        <tr>
          <td align="center">
            <p class="error-msg">{{errorMsg}}</p>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="full-width">
              <input matInput placeholder="Username" [(ngModel)]="login" name="login" required>
              <mat-icon matPrefix>person</mat-icon>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="full-width">
              <mat-icon matPrefix>lock</mat-icon>
              <input matInput class="passwd-input" placeholder="Password" [(ngModel)]="passwd" [type]="showPassword ? 'text' : 'password'" (keyup.enter)="userLogIn()" name="passwd" required>
              <mat-icon (mousedown)="mouseDown()" (mouseup)="mouseUp()" class="eye-btn" matSuffix>visibility</mat-icon>
            </mat-form-field>
          </td>
        </tr>
      </table>
    </form>

    <!--   <mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner> -->
  </mat-card-content>
  <mat-card-actions *ngIf="!app.isMaintenance()">
    <button mat-raised-button (click)="userLogIn()" color="primary" class="full-width">Login</button>
  </mat-card-actions>
  <mat-card-footer *ngIf="!app.isMaintenance()">
    <button mat-stroked-button (click)="forgotPassword()">Forgot password?</button>
  </mat-card-footer>
</mat-card>
