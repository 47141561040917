import { Component, OnInit } from "@angular/core";
import { DataAccessService } from "../core/services/data-access.service";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ISimpleListElement } from "../core/models/ISimpleListElement";
import { Router, ActivatedRoute, createUrlTreeFromSnapshot, UrlSerializer } from "@angular/router";
import Swal from "sweetalert2";
import { IClaim } from "../core/models/IClaim";
import { ProceedClaimDialog } from "./proceed_claim_dialog";
import { UploadService } from "../core/services/upload-service";
import { HttpResponse } from "@angular/common/http";
import { AttachmentDialog } from "./attachment_dialog";
import { AppComponent } from "../app.component";

@Component({
  selector: "claim_edit",
  templateUrl: "./claim_edit.html",
  styleUrls: ["./claim_edit.scss"],
  //providers: [DataAccessService]
})
export class ClaimEditComponent implements OnInit {
  claim: IClaim = {} as IClaim;
  currencies: ISimpleListElement[] = [];
  claim_reason_codes: ISimpleListElement[] = [];

  supplier_attachments: any;
  customer_attachments: any;
  claim_reason_code_ids: number[];

  mode: string;
  id: string;
  line_id: string;

  isSupplier: boolean;
  isCustomer: boolean;
  isPNE: boolean;

  currency_id: any;
  showRejectButton: boolean = false;
  showAcceptButton: boolean = false;

  dialogRef: any;
  customReasonComment: number;
  showHint: boolean = false;

  constructor(
    public dialog: MatDialog,
    private dataAccess: DataAccessService,
    private route: ActivatedRoute,
    private router: Router,
    private urlSerializer: UrlSerializer,
    private upload: UploadService,
    private app: AppComponent
  ) {}

  ngOnInit() {
    this.showRejectButton = false;
    this.showAcceptButton = false;
    this.mode = this.route.snapshot.routeConfig.path.split("/")[0];

    if (this.mode == "new") {
      this.line_id = this.route.snapshot.paramMap.get("id");
    } else if (this.mode == "edit") {
      this.id = this.route.snapshot.paramMap.get("id");
    }

    let role =
      (this.dataAccess.getLoginData() as any) &&
      (this.dataAccess.getLoginData() as any).roles
        ? (this.dataAccess.getLoginData() as any).roles.filter(
            (x) => x.id == (this.dataAccess.getLoginData() as any).user_role_id
          )[0]
        : null;

    this.isSupplier = role ? role.is_supplier : false;
    this.isCustomer = role ? role.is_customer : false;
    this.isPNE =
      !this.isSupplier &&
      !this.isCustomer &&
      (this.dataAccess.getLoginData() as any).su_roles &&
      (this.dataAccess.getLoginData() as any).su_roles.filter(
        (x) => x.role === "PNE"
      ).length > 0;

    this.refreshList();
  }

  refreshList() {
    this.dataAccess
      .getJSONPost(
        "get_claims",
        [],
        [
          { k: "p_line_id", v: this.line_id },
          { k: "p_id", v: this.id },
          { k: "p_show_closed", v: true },
        ]
      )
      .then((data) => {
        this.claim = data[0];
        this.claim.claim_reason_code = data[0].claim_reason_codes;
        this.claim_reason_code_ids = data[0].claim_reason_code_ids
          ? data[0].claim_reason_code_ids.split(",").map(function (item) {
              return parseInt(item, 10);
            })
          : null;

        this.showRejectButton =
          this.id &&
          !["AC", "RJ"].includes(this.claim.claim_status) &&
          ((this.isCustomer &&
            ["CR", "RJ-GA"].includes(this.claim.claim_status)) ||
            (this.isSupplier && this.claim.claim_status == "NW") ||
            (this.isPNE && this.claim.claim_status == "AC-GA"));

        this.showAcceptButton =
          this.id &&
          !["AC", "RJ"].includes(this.claim.claim_status) &&
          ((this.isCustomer &&
            ["CR", "RJ-GA"].includes(this.claim.claim_status)) ||
            (this.isSupplier &&
              ["NW", "AC-P"].includes(this.claim.claim_status)) ||
            (this.isPNE && this.claim.claim_status == "AC-GA"));

        this.dataAccess
          .getJSONPost(
            "get_claim_reason_codes_simple_list",
            [],
            [{ k: "p_item_id", v: this.claim.item_id }]
          )
          .then((data) => {
            this.claim_reason_codes = Array.isArray(data) ? data : [];

            if (this.claim_reason_codes.length == 0 && this.mode == "new") {
              Swal.fire({
                icon: "error",
                title: "Error!",
                html: "There are no reason codes defined for this item. Contact IKEA.",
                willClose: () => {
                  this.router.navigate(["/claims"]);
                },
              });
            } else {
              if (
                this.claim_reason_codes.filter(
                  (x) =>
                    (x.name == "others" || x.name == "Others") &&
                    this.claim_reason_code_ids.includes(x.id)
                ).length > 0
              ) {
                this.showHint = true;
              } else {
                this.showHint = false;
              }
            }
          });

        this.refreshAttachments();
      });
  }

  refreshAttachments(): void {
    this.dataAccess
      .getJSONPost(
        "get_claim_attachments",
        [],
        [
          { k: "p_claim_id", v: this.id },
          { k: "p_category", v: "supplier" },
        ]
      )
      .then((data) => {
        this.supplier_attachments = Array.isArray(data) ? data : [];
      });

    this.dataAccess
      .getJSONPost(
        "get_claim_attachments",
        [],
        [
          { k: "p_claim_id", v: this.id },
          { k: "p_category", v: "customer" },
        ]
      )
      .then((data) => {
        this.customer_attachments = Array.isArray(data) ? data : [];
      });
  }

  updateCost() {
    if (this.claim.claimed_qty && this.claim.ga_price) {
      this.claim.claimed_cost = +(
        parseFloat(this.claim.claimed_qty.toString().replace(",", ".")) *
        this.claim.ga_price
      ).toFixed(2);
    }
  }

  saveClaim() {
    // there is a reason code in database but it's not on the list anymore (e.g. invalid data)
    if (
      this.claim.claim_reason_code_id &&
      this.claim_reason_codes.filter(
        (x) => x.id == this.claim.claim_reason_code_id
      ).length == 0
    ) {
      Swal.fire(
        "Error!",
        "Claim reason code is already invalid. Please select another reason code.",
        "error"
      );
      return;
    }

    if (
      this.claim.claimed_qty != null &&
      this.claim.claimed_qty.toString() != "" &&
      !/^\d+([\.\,]\d+)?$/.test(this.claim.claimed_qty.toString())
    ) {
      Swal.fire("Error!", "Incorrect claimed qty format.", "error");
      return;
    }

    if (
      this.claim.manual_claimed_cost != null &&
      this.claim.manual_claimed_cost.toString() != "" &&
      !/^\d+([\.\,]\d+)?$/.test(this.claim.manual_claimed_cost.toString())
    ) {
      Swal.fire("Error!", "Incorrect manual claimed cost format.", "error");
      return;
    }

    this.dataAccess
      .getJSONPost(
        "save_claim",
        [],
        [
          { k: "p_id", v: this.id },
          { k: "p_claim_no", v: this.claim.claim_no },
          { k: "p_claim_status", v: this.claim.claim_status },
          { k: "p_order_line_id", v: this.line_id },
          {
            k: "p_claimed_qty",
            v: this.claim.claimed_qty
              ? parseFloat(this.claim.claimed_qty.toString().replace(",", "."))
              : null,
          },
          { k: "p_claimed_cost", v: this.claim.claimed_cost },
          {
            k: "p_manual_claimed_cost",
            v: this.claim.manual_claimed_cost
              ? parseFloat(
                  this.claim.manual_claimed_cost.toString().replace(",", ".")
                )
              : null,
          },
          { k: "p_currency_id", v: this.claim.currency_id },
          {
            k: "p_claim_reason_code_ids",
            v: this.claim_reason_code_ids
              ? this.claim_reason_code_ids.toString()
              : "",
          },
          { k: "p_suggested_actions", v: this.claim.suggested_actions },
          { k: "p_customers_comments", v: this.claim.customers_comments },
          { k: "p_suppliers_comments", v: this.claim.suppliers_comments },
          { k: "p_customer_attachments", v: this.claim.customer_attachments },
          { k: "p_supplier_attachments", v: this.claim.supplier_attachments },
          { k: "p_safety_alarm", v: this.claim.safety_alarm },
        ]
      )
      .then((data) => {
        if (data.res != 0) {
          Swal.fire("Error!", data.msg, "error");
        } else {
          //this.claim.claim_reason_code = (this.claim_reason_codes.filter(x => x.id == this.claim.claim_reason_code_id))[0].name;

          Swal.fire({
            icon: "success",
            title: "Claim saved!",
            showConfirmButton: false,
            timer: 1000,
            willClose: () => {
              if (!this.id && data.id) {
                this.id = data.id;
                this.router.navigate(["/claim_edit/edit", this.id]);
              }
            },
          });
        }
      });
  }

  proceed(action): void {
    let tmpData: IClaim[] = [];
    this.claim.id = parseInt(this.id);

    tmpData.push(this.claim);

    this.dialogRef = this.dialog.open(ProceedClaimDialog, {
      data: {
        lines: tmpData,
        operation: action,
        showWarning: true,
      },
    });

    this.dialogRef.afterClosed().subscribe(() => {
      this.refreshList();
    });
  }

  showSelectFileDialog() {
    if (!this.id) {
      Swal.fire({
        icon: "warning",
        html: "Save your claim before attaching files.",
      });
    } else {
      document.getElementById("attachmentUpload").click();
    }
  }

  selectFile(event) {
    if (event.target.files[0].size > 4_048_576) {
      Swal.fire(
        "Error: Maximum size exceeded.",
        "File maximum size is 1MB.",
        "error"
      );
    } else {
      this.uploadFile(event.target.files);
      event.srcElement.value = null;
    }
  }

  uploadFile(files: FileList) {
    if (files.length == 0) {
      return;
    }

    let result: string = "";
    let success: boolean = true;
    let filesCount: number = files.length;
    let counter: number = 0;

    Array.from(files).forEach((file) => {
      console.log(file.type);
      let uploadURL = this.dataAccess.saveFile(
        "save_attachment",
        [],
        [
          { k: "p_claim_id", v: this.id },
          { k: "p_category", v: this.isSupplier ? "supplier" : "customer" },
        ]
      );

      if (
        [
          "image/jpeg",
          "image/png",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/pdf",
          "text/plain",
          "video/mp4",
        ].includes(file.type)
      ) {
        this.upload.uploadFile(uploadURL, file).subscribe({
          next: (v) => {
            if (v instanceof HttpResponse) {
              if (!((v.body as any).res == 0)) {
                success = false;
                result +=
                  '<b><span class="mark mark-failed"></span>' +
                  file.name +
                  "</b>: " +
                  (v.body as any).msg +
                  "<br/>";
              } else {
                result +=
                  '<b><span class="mark mark-successful"></span>' +
                  file.name +
                  "</b>: Upload successful.<br/>";
              }
            }
          },
          error: (e) => {
            this.app.saveToLog("CLAIMS upload error: " + e.statusText, [file.name, file.type].join(', '), this.urlSerializer.serialize(createUrlTreeFromSnapshot(this.route.snapshot, ['.'])));
            success = false;
            result +=
              "<b>" +
              file.name +
              "</b>: upload error. " +
              e.statusText +
              "<br/>";
            counter++;
            if (counter == filesCount) {
              this.showResponse(success, result);
            }
          },
          complete: () => {
            counter++;
            if (counter == filesCount) {
              this.showResponse(success, result);
            }
          },
        });
      } else {
        counter++;
        success = false;
        result +=
          '<b><span class="mark mark-failed"></span>' +
          file.name +
          "</b>: File format not allowed. Allowed file formats: .jpg, .png, .xlsx, .xls, .docx, .doc, .mp4, .pdf, .txt.<br/>";

        if (counter == filesCount) {
          this.showResponse(success, result);
        }
      }
    });
  }

  showResponse(success: boolean, result: string) {
    Swal.fire({
      icon: success ? "success" : "error",
      title: success ? "Upload complete" : "Error!",
      html: result,
      customClass: {
        htmlContainer: "popup-align-left",
      },
      willClose: () => {
        this.refreshAttachments();
      },
    });

    if (this.dialogRef && this.dialogRef.componentInstance)
      this.dialogRef.componentInstance.refreshAttachments();
  }

  deleteCustomerAttachment(e, id: number) {
    if (this.isCustomer && ["CR"].includes(this.claim.claim_status)) {
      this.deleteAttachment(e, id);
    }
  }

  deleteSupplierAttachment(e, id: number) {
    if (this.isSupplier && ["NW", "AC-P"].includes(this.claim.claim_status)) {
      this.deleteAttachment(e, id);
    }
  }

  deleteAttachment(e, id: number) {
    e.preventDefault();

    let dialogRef = this.dialog.open(AttachmentDialog, {
      data: {
        id: id,
        action: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshAttachments();
    });
  }

  selectionChanged(e) {
    if (
      this.claim_reason_codes.filter(
        (x) =>
          (x.name == "others" || x.name == "Others") && e.value.includes(x.id)
      ).length > 0
    ) {
      this.showHint = true;
    } else {
      this.showHint = false;
    }
  }
}
