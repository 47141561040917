<mat-toolbar class="side-elements" color="primary" *ngIf="showSidePanels()">
  <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>

  <mat-toolbar-row class="mat-elevation-z6">

    <button mat-icon-button routerLinkActive="active" [routerLink]="['/home']">
      <mat-icon>dashboard</mat-icon>
    </button>

    <span>GAMS</span>
    <div class="current-version">v3.1</div>

    <div *ngIf="showMaintenanceWarning()" style="font-size: small; margin-left: 15px;" [innerHTML]="getMaintenanceWarning()"></div>

    <span class="example-spacer"></span>
    <div class="toolbar-roles">
      <div *ngFor="let role of roles" class="toolbar-role">
        <div *ngIf="getSelectedUserRoleId() == role.id">
          <button class="role-btn" mat-raised-button disableRipple>
            <mat-icon>{{role.is_supplier ? 'business' : 'location_city'}}</mat-icon>{{role.factory}}
          </button>
        </div>

        <div *ngIf="getSelectedUserRoleId() != role.id" class="toolbar-role">
          <button class="role-btn" mat-raised-button color="primary" (click)="setUserRole(role.id)">
            <mat-icon>{{role.is_supplier ? 'business' : 'location_city'}}</mat-icon>{{role.factory}}
          </button>
        </div>
      </div>
    </div>


    <button mat-icon-button [matMenuTriggerFor]="helpMenu">
      <mat-icon>help_outline</mat-icon>
    </button>
    <mat-menu #helpMenu="matMenu">
      <a mat-menu-item target="_blank" href="/downloads/User Manual.pdf" title="User Manual.pdf">
        <mat-icon>book</mat-icon>User Manual
      </a>
      <a mat-menu-item target="_blank" href="/downloads/Leather and PU coated fabric claim routine.pdf"
        title="Leather and PU coated fabric claim routine.pdf">
        <mat-icon>description</mat-icon>Leather and PU coated fabric claim routine
      </a>
      <button mat-menu-item routerLinkActive="active" [routerLink]="['/faq']">
        <mat-icon>contact_support</mat-icon>FAQ
      </button>
      <button mat-menu-item routerLinkActive="active" [routerLink]="['/help']">
        <mat-icon>get_app</mat-icon>Upload templates
      </button>
      <button mat-menu-item routerLinkActive="active" [routerLink]="['/releasenotes']">
        <mat-icon>new_releases</mat-icon>Release notes
      </button>
    </mat-menu>


    <button mat-icon-button [matMenuTriggerFor]="userMenu">
      <mat-icon>person_outline</mat-icon>
    </button>
    <mat-menu #userMenu="matMenu">
      <button mat-menu-item (click)="changePassword()">
        <mat-icon>settings</mat-icon>Change password
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>Log out
      </button>
    </mat-menu>

    <span class="logged-in-user">{{this.login}}</span>

  </mat-toolbar-row>
  <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>

</mat-toolbar>

<mat-drawer-container class="example-container" id="example_container"
  [ngStyle]="{'height': (showSidePanels() ? 'calc(100% - 74px)' : '100%')}" autosize>
  <mat-drawer #drawer class="example-sidenav mat-elevation-z8 side-elements" mode="side" opened
    *ngIf="showSidePanels()">
    <mat-action-list>
      <button mat-list-item (click)="changeLeftPanel()">
        <mat-icon *ngIf="showLeftCaptions">chevron_left</mat-icon>
        <mat-icon *ngIf="!showLeftCaptions">chevron_right</mat-icon>
      </button>

      <button mat-list-item routerLinkActive="active" [routerLink]="['/home']">
        <mat-icon>home</mat-icon>
        <p *ngIf="showLeftCaptions" class="menu-button">Home</p>
      </button>

      <button mat-list-item routerLinkActive="active" [routerLink]="['/orders']">
        <mat-icon svgIcon="orders" aria-hidden="false" aria-label="orders"></mat-icon>
        <p *ngIf="showLeftCaptions" class="menu-button">Orders</p>
      </button>

      <button mat-list-item routerLinkActive="active" [routerLink]="['/claims']">
        <mat-icon svgIcon="claims" aria-hidden="false" aria-label="claims"></mat-icon>
        <p *ngIf="showLeftCaptions" class="menu-button">Claims</p>
      </button>

      <button mat-list-item routerLinkActive="active" [routerLink]="['/forecasts']"
        *ngIf="haveAccessToModule('forecasts')">
        <mat-icon svgIcon="forecast"></mat-icon>
        <p *ngIf="showLeftCaptions" class="menu-button">Forecasts</p>
      </button>

      <mat-expansion-panel *ngIf="showLeftCaptions">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <button mat-list-item routerLinkActive="active">
              <mat-icon>settings</mat-icon>
              <p class="menu-button">Configuration</p>
            </button>

          </mat-panel-title>
        </mat-expansion-panel-header>

        <button mat-list-item routerLinkActive="active" [routerLink]="['/users']" *ngIf="haveAccessToModule('users')">
          <p class="menu-button">Users</p>
        </button>

        <button mat-list-item routerLinkActive="active" [routerLink]="['/products']">
          <p class="menu-button">Products</p>
        </button>

        <button mat-list-item routerLinkActive="active" [routerLink]="['/factories']">
          <p class="menu-button">Factories</p>
        </button>

        <button mat-list-item routerLinkActive="active" [routerLink]="['/parameters']"
          *ngIf="haveAccessToModule('parameters')">
          <p class="menu-button">Parameters Setting</p>
        </button>

        <button mat-list-item routerLinkActive="active" [routerLink]="['/agreements']"
          *ngIf="haveAccessToModule('agreements')">
          <p class="menu-button">General Agreement</p>
        </button>

        <button mat-list-item routerLinkActive="active" [routerLink]="['/matrix']" *ngIf="haveAccessToModule('matrix')">
          <p class="menu-button">Matrix</p>
        </button>

      </mat-expansion-panel>

      <button *ngIf="!showLeftCaptions" mat-list-item routerLinkActive="active" [routerLink]="['/configmodules']">
        <mat-icon>settings</mat-icon>
      </button>

      <button mat-list-item routerLinkActive="active" [routerLink]="['/kpireports']" *ngIf="haveAccessToModule('kpi')">
        <mat-icon>insert_chart_outlined</mat-icon>
        <p *ngIf="showLeftCaptions" class="menu-button">KPI Reports</p>
      </button>

      <button mat-list-item routerLinkActive="active" [routerLink]="['/calendar']"
        *ngIf="haveAccessToModule('calendar')">
        <!-- <button mat-list-item> -->
        <mat-icon>today</mat-icon>
        <p *ngIf="showLeftCaptions" class="menu-button">Calendar</p>
      </button>

      <button mat-list-item routerLinkActive="active" [routerLink]="['/communication']"
        *ngIf="haveAccessToModule('communication')">
        <mat-icon>mail</mat-icon>
        <p *ngIf="showLeftCaptions" class="menu-button">Communication</p>
      </button>
    </mat-action-list>

    <div class="db">{{this.db}}</div>

  </mat-drawer>

  <mat-drawer class="example-sidenav mat-elevation-z8 side-elements" mode="side" opened position="end"
    *ngIf="showSidePanels() && (haveAccessToModule('early_warnings') || filtersRightPanel.length>0)">

    <mat-action-list>
      <button mat-list-item (click)="changeRightPanel()">
        <mat-icon *ngIf="showRightCaptions">chevron_right</mat-icon>
        <mat-icon *ngIf="!showRightCaptions">chevron_left</mat-icon>
      </button>

      <button mat-list-item routerLinkActive="active" [routerLink]="['/warnings']"
        *ngIf="haveAccessToModule('early_warnings')">
        <mat-icon matBadge="{{earlyWarningsCount}}" matBadgeColor="{{earlyWarningsCount == 0 ? 'primary' : 'accent'}}">
          warning</mat-icon>
        <p *ngIf="showRightCaptions" class="menu-button">Early Warnings</p>
      </button>

      <button mat-list-item *ngFor="let filter of filtersRightPanel" routerLinkActive="active"
        [routerLink]="['/' + filter.module + '/filter', filter?.id]" (contextmenu)="filterClick($event, filter.id)">
        <mat-icon svgIcon="{{filter.module}}" aria-hidden="false" id="filterIcon_{{filter?.id}}"
          matBadge="{{filter?.number ? filter.number : 0}}" title="{{filter?.search_name + ': ' + filter?.number}}" matBadgeColor="primary"
          [ngStyle]="styleBadge(filter?.id, filter?.color)">
        </mat-icon>
        <p *ngIf="showRightCaptions" class="menu-button" title="{{filter?.search_name + ': ' + filter?.number}}">
          {{filter?.search_name.toString().substring(0,15) + (filter.search_name.length>15 ? '...' : '')}}</p>
      </button>

    </mat-action-list>

  </mat-drawer>

  <div class="example-sidenav-content">

    <router-outlet></router-outlet>

  </div>

  <div class="notranslate" id='config_data_tmp' hidden></div>
</mat-drawer-container>
