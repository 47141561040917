<mat-toolbar class="custom-toolbar">
  <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
  <mat-toolbar-row class="mat-elevation-z6">
    <span>Release notes</span>
  </mat-toolbar-row>
  <mat-toolbar-row class="mat-elevation-z6" style="height:5px;"></mat-toolbar-row>
</mat-toolbar>

<mat-card>
  <div class="release-notes-container">
    <!-- <h1>v2.01</h1>
    <div class="release-date">?</div>
    <ul>
      <li>GA supplier factory interface integration between SSCM and GAMS</li>
      <li>Early warning with interface information</li>
    </ul> -->

    <h1>v3.1</h1>
    <div class="release-date">2025-03-19</div>
    <ul>
      <li>Secondary categories for suppliers and customers: in addition to main category it is now possible to add secondary categories to suppliers and customers.</li>
      <li>GA Supplier Calendar handling improved: while editing a line of an order, the calendar allows selecting only the dates that align with the item's lead time and the supplier's calendar schedule.</li>
    </ul>

    <h1>v3.0</h1>
    <div class="release-date">2024-01-29</div>
    <h3>Welcome to GAMS 3.0 - up-to-date technology for best user experience</h3>

    <h1>Changes in GAMS:</h1>
    <ul>
      <li>Implementation of the laravel framework and upgrade of angular version.</li>
    </ul>

    <h1>Also changes in GAMS modules:</h1>
    <ul>
      <li><b>Early warnings</b></li>
      <li>If there are not any early warning that can be show for the user on home page system will show information "You have no early warnings".</li>
      <li>Early warnings with types: Order update, claim update, forecast update, product lead time update are automatically archiving after 7 days of its creation. For the rest of early warnings types there is automatic archiving after 90 days (if case of early warning is solved).</li>
      <li>Early warnings for which the user take an action are automatically check as solved. From now user can see on the left side of early warning color red for early warnings which need to take an action, and green for solved early warnings.</li>
      <li>Added name of column in early warnings module in which system display early warning type.</li>

      <li><b>Forecasts</b></li>
      <li>While forecasts creation and edition if the user will not fill quantity for any week system will automatically put there 0 (zero). Also in frozen weeks.</li>
      <li>Saving the filters while moving beetwen tabs in Forecasts module.</li>
      <li>Added possibility to unselect all selected categories in filter.</li>
      <li>While forecast creation on item list there are only items which regarding to active matrixes beetwen customer and GA supplier.</li>

      <li><b>Forecasts and KPIs</b></li>
      <li>Data is no longer loading automatically while user enters module. Now its needed to load data manually with or without filters selection.</li>

      <li><b>Configuration modules</b></li>
      <li>Added possibility to edit one record without category selection with double-click.</li>

      <li><b>Claims</b></li>
      <li>From now there is restriction for types of the attachements. The only types that can be add are:  .jpg, .png, .xlsx, .xls, .docx, .doc, .mp4, .pdf, .txt</li>

      <li><b>Factories</b></li>
      <li>Added funcionality to specify number of frozen weeks for selected GA supplier. That information is taking part while forecast creation or edition.</li>

      <li><b>Whole system</b></li>
      <li>While putting an expression in search field in each module the system will now display selected expression with its boldness.</li>
    </ul>

    <h1>v2.0</h1>
    <div class="release-date">2022-09-26</div>
    <h3>Welcome to GAMS 2.0 - up-to-date technology for best user experience</h3>
    <ul>
      <li><b>Angular and all dependencies upgraded to the latest version</b></li>
      <li>New KPIs - PPD, Flipover</li>
      <li>Login on Enter press</li>
      <li>KPI filters and KPI data table responsive to screen size</li>
      <li>Charts - optimized with the newest version</li>
      <li>Additional regions</li>
      <li>Security update</li>
      <li>Fixed filtering orders by dates</li>
      <li>Default FY in filters changed on September 1st</li>
      <li>Scrolling toolbar with multiple roles</li>
      <li>Duplicate roles selection prevented</li>
    </ul>

    <h1>v1.14</h1>
    <div class="release-date">2021-08-17</div>
    <ul>
      <li>Changes in processing order line from status CF to DP (abillity to proceed order line with split or cancel the
        part of confirmed qty)</li>
    </ul>
    <h1>v1.13</h1>
    <div class="release-date">2021-07-15</div>
    <ul>
      <li>Changes in APL export file</li>
    </ul>
    <h1>v1.12</h1>
    <div class="release-date">2021-05-28</div>
    <ul>
      <li>While processing order line from status CF to DP user may set date between today's date to 3 days back</li>
    </ul>
    <h1>v1.11</h1>
    <div class="release-date">2021-01-21</div>
    <ul>
      <li>User manual has been updated with tables included reason codes in chapters 4.1.3.1, 4.1.3.4, 4.1.4.4 and
        4.1.4.1</li>
    </ul>

    <h1>v1.10</h1>
    <div class="release-date">2021-01-20</div>
    <ul>
      <li>Specific windows label changed from Category to Main Category.</li>
    </ul>

    <h1>v1.09</h1>
    <div class="release-date">2021-01-19</div>
    <ul>
      <li>Adding claim document.</li>
      <li>Categories Uphostery Frames and Work Chairs have been merged into category Comfort Seating.</li>
      <li>Changing the message content using the set invalid in matrix.</li>
    </ul>

    <h1>v1.08</h1>
    <div class="release-date">2021-01-14</div>
    <ul>
      <li>Change of GFAR expression to GFXR.</li>
    </ul>

    <h1>v1.07</h1>
    <div class="release-date">2020-12-02</div>
    <ul>
      <li>Fixed: Sorting Forecast accuracy chart.</li>
    </ul>

    <h1>v1.06</h1>
    <div class="release-date">2020-12-01</div>
    <ul>
      <li>Dispatch calendar.</li>
    </ul>

    <h1>v1.05</h1>
    <div class="release-date">2020-10-20</div>
    <ul>
      <li>Sorting Forecast export file (upload template sheet).</li>
      <li>Added Item no. to Product Lead Time changed warning.</li>
      <li>Fixed: receiving daily early warnings.</li>
      <li>Home filters display improvement when number of rows is 5 digits.</li>
      <li>Order no. column width adjusted to migrated order no. format.</li>
      <li>Decreasing frequency of filters calculation for performance improvement.</li>
    </ul>

    <h1>v1.04</h1>
    <div class="release-date">2020-10-06</div>
    <ul>
      <li>Improvements in KPI formatting.</li>
      <li>Duplicate forecasts issue resolved.</li>
      <li>Sorted lists in forecasts filter.</li>
      <li>Allowing to reuse the same product type/product name/item no. if the other existing ones are invalid (example:
        moving items between categories).</li>
      <li>Added checking if selected factory has necessary role while uploading matrix.</li>
      <li>Small change in the OTD Sender GA calculation.</li>
      <li>Frequently Asked Questions section.</li>
      <li>Fixed: Sorting issue in KPI module.</li>
      <li>Added possibility to change Matrix, GA, Product Lead Time "valid to" date using upload.</li>
    </ul>

    <h1>v1.03</h1>
    <div class="release-date">2020-09-10</div>
    <ul>
      <li>Added a possibility to choose the same Factory as GA Supplier and Customer in Matrix.</li>
      <li>Corrections in the Forecast upload template.</li>
    </ul>

    <h1>v1.02</h1>
    <div class="release-date">2020-09-09</div>
    <ul>
      <li>Improved performance of Forecasts.</li>
      <li>Added search fields for filter lists in Orders and Claims.</li>
      <li>Removed unnecessary scrollbar in Early warnings settings.</li>
      <li>Removed unnecessary scrollbar in Early Warnings on the home page.</li>
      <li>Added no. of rows succeeded information during the upload.</li>
      <li>Fixed: Too many early warnings while uploading the forecast.</li>
      <li>Added Release Notes.</li>
      <li>Switching between Products tabs leaves category unchanged.</li>
    </ul>

    <h1>v1.01</h1>
    <div class="release-date">2020-09-07</div>
    <ul>
      <li>Fixed: GDPR accepting error while using Google Translate.</li>
      <li>Fixed: Duplicate lead time display.</li>
      <li>Fixed: Problem with uploading prices with comma.</li>
      <li>Fixed: Duplicate password reset email.</li>
      <li>Fixed: Leaving forecast empty error - no warning.</li>
      <li>Fixed: Double-click on proceed "OK" button proceeded the order two times.</li>
      <li>Fixed: Actual price for payment value replaced by default.</li>
      <li>Fixed: Difference in Forecast upload and export template.</li>
      <li>Fixed: Invalid users received early warnings.</li>
      <li>Improved performance of Matrix.</li>
    </ul>


    <h1>v1.0</h1>
    <div class="release-date">2020-08-29</div>
  </div>
</mat-card>
