<div hidden>
  <input
    type="file"
    id="fileUpload"
    (change)="selectFile($event)"
    accept=".xlsx"
  />
</div>

<mat-toolbar class="custom-toolbar">
  <mat-toolbar-row
    class="mat-elevation-z6"
    style="height: 5px"
  ></mat-toolbar-row>
  <mat-toolbar-row class="mat-elevation-z6">
    <span
      ><a
        href="javascript:void(0)"
        (click)="refreshList()"
        style="text-decoration: none"
        >Factories</a
      ></span
    >
    <span class="example-spacer"></span>

    <div class="example-button-row">
      <button
        *ngIf="
          (this.allowEdit || this.allowAll) && this.tabGroup.selectedIndex === 0
        "
        mat-raised-button
        color="primary"
        (click)="openSetFrozenWeekDialog()"
        [disabled]="this.selection.selected.length !== 1"
      >
        <mat-icon>date_range</mat-icon>Set frozen weeks
      </button>
      <button
        *ngIf="this.allowAll"
        mat-raised-button
        color="primary"
        (click)="openAddDialog()"
      >
        <mat-icon>add</mat-icon>Add
      </button>
      <button
        *ngIf="(this.allowEdit || this.allowAll)"
        mat-raised-button
        color="primary"
        (click)="openEditDialog()"
        [disabled]="this.selection.selected.length != 1"
      >
        <mat-icon>edit</mat-icon>Edit
      </button>
      <button
        *ngIf="this.allowAll"
        mat-raised-button
        color="primary"
        (click)="openEditCategoriesDialog()"
        [disabled]="this.selection.selected.length != 1"
      >
        <mat-icon>edit</mat-icon>Edit category
      </button>
      <button
        *ngIf="this.allowAll"
        mat-raised-button
        color="primary"
        (click)="delete()"
        [disabled]="this.selection.selected.length == 0"
      >
        <mat-icon>block</mat-icon>Set as invalid
      </button>
      <button mat-raised-button color="primary" (click)="export()">
        <mat-icon>cloud_download</mat-icon> Export
      </button>
      <button
        *ngIf="this.allowAll"
        mat-raised-button
        color="primary"
        (click)="showSelectFileDialog()"
      >
        <mat-icon>cloud_upload</mat-icon> Upload
      </button>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row
    class="mat-elevation-z6"
    style="height: 5px"
  ></mat-toolbar-row>
</mat-toolbar>

<app-my-loader></app-my-loader>

<mat-card>
  <mat-form-field style="width: 100%">
    <mat-label>Main Category</mat-label>
    <mat-select
      required
      [(value)]="categoryId"
      (selectionChange)="refreshList()"
    >
      <mat-option *ngFor="let category of categories" [value]="category.id">
        {{ category.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-tab-group #tabGroup (selectedTabChange)="selectedTabChange()">
    <mat-tab label="GA Suppliers">
      <mat-card>
        <table width="100%">
          <tr>
            <td width="50%">
              <mat-form-field>
                <input
                  matInput
                  [(ngModel)]="filterText"
                  (keyup)="applyFilter($event.target.value)"
                  placeholder="Filter"
                />
              </mat-form-field>
            </td>
            <td align="right">
              <mat-checkbox [(ngModel)]="showInvalid" (change)="refreshList()"
                >Show invalid records</mat-checkbox
              >
            </td>
          </tr>
        </table>

        <div class="grid-container mat-elevation-z8">
          <table mat-table #table1 [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? masterToggle($event) : null"
                  [checked]="selection.hasValue() && isEntirePageSelected()"
                  [indeterminate]="
                    selection.hasValue() && !isEntirePageSelected()
                  "
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="category_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Main Category
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.category_name ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="category_others_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Secondary Category
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.category_others_name ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="factory_no">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                GA Supplier No.
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.factory_no ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="factory_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                GA Supplier Name
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.factory_name ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="region">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Region</th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="row.region ?? '' | highlightSearch : filterText"
              ></td>
            </ng-container>
            <ng-container matColumnDef="bdm_user">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>BDM</th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="row.bdm_user ?? '' | highlightSearch : filterText"
              ></td>
            </ng-container>
            <ng-container matColumnDef="bd_user">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>BD</th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="row.bd_user ?? '' | highlightSearch : filterText"
              ></td>
            </ng-container>
            <ng-container matColumnDef="pne_user">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>PNE</th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="row.pne_user ?? '' | highlightSearch : filterText"
              ></td>
            </ng-container>
            <ng-container matColumnDef="sp_user">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>SP</th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="row.sp_user ?? '' | highlightSearch : filterText"
              ></td>
            </ng-container>
            <ng-container matColumnDef="office_address">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Office address
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.office_address ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="pick_up_address">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Pick up address
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.pick_up_address ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="unload_address">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Unload address
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.unload_address ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="invoice_address">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Invoice address
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.invoice_address ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="bank">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Bank</th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="row.bank ?? '' | highlightSearch : filterText"
              ></td>
            </ng-container>
            <ng-container matColumnDef="frozen_weeks">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Frozen weeks
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.frozen_weeks ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="last_update_dt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Last update date
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.last_update_dt ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="last_update_user">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Last updated by
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.last_update_user ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="invalid_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Valid to
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.invalid_date ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              (click)="$event.ctrlKey ? checkRow(row) : null"
              [ngClass]="{ highlight: selection.isSelected(row) }"
            ></tr>
          </table>

          <mat-paginator
            #pag1
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
            class="mat-paginator-sticky"
          ></mat-paginator>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Customers">
      <mat-card>
        <table width="100%">
          <tr>
            <td width="50%">
              <mat-form-field>
                <input
                  matInput
                  [(ngModel)]="filterText"
                  (keyup)="applyFilter($event.target.value)"
                  placeholder="Filter"
                />
              </mat-form-field>
            </td>
            <td align="right">
              <mat-checkbox [(ngModel)]="showInvalid" (change)="refreshList()"
                >Show invalid records</mat-checkbox
              >
            </td>
          </tr>
        </table>

        <div class="grid-container mat-elevation-z8">
          <table mat-table #table2 [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? masterToggle($event) : null"
                  [checked]="selection.hasValue() && isEntirePageSelected()"
                  [indeterminate]="
                    selection.hasValue() && !isEntirePageSelected()
                  "
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="category_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Main Category
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.category_name ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="category_others_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Secondary Category
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.category_others_name ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="factory_no">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Customer No.
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.factory_no ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="factory_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Customer Name
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.factory_name ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="region">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Region</th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="row.region ?? '' | highlightSearch : filterText"
              ></td>
            </ng-container>
            <ng-container matColumnDef="bdm_user">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>BDM</th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="row.bdm_user ?? '' | highlightSearch : filterText"
              ></td>
            </ng-container>
            <ng-container matColumnDef="bd_user">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>BD</th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="row.bd_user ?? '' | highlightSearch : filterText"
              ></td>
            </ng-container>
            <ng-container matColumnDef="pne_user">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>PNE</th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="row.pne_user ?? '' | highlightSearch : filterText"
              ></td>
            </ng-container>
            <ng-container matColumnDef="sp_user">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>SP</th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="row.sp_user ?? '' | highlightSearch : filterText"
              ></td>
            </ng-container>
            <ng-container matColumnDef="office_address">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Office address
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.office_address ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="pick_up_address">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Pick up address
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.pick_up_address ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="unload_address">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Unload address
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.unload_address ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="invoice_address">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Invoice address
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.invoice_address ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="bank">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Bank</th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="row.bank ?? '' | highlightSearch : filterText"
              ></td>
            </ng-container>
            <ng-container matColumnDef="frozen_weeks">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Frozen weeks
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.frozen_weeks ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="last_update_dt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Last update date
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.last_update_dt ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="last_update_user">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Last updated by
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.last_update_user ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>
            <ng-container matColumnDef="invalid_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Valid to
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                [innerHTML]="
                  row.invalid_date ?? '' | highlightSearch : filterText
                "
              ></td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              (dblclick)="$event.ctrlKey ? null : openEditDialog(row)"
              (click)="$event.ctrlKey ? checkRow(row) : null"
              [ngClass]="{ highlight: selection.isSelected(row) }"
            ></tr>
          </table>

          <mat-paginator
            #pag2
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
            class="mat-paginator-sticky"
          ></mat-paginator>
        </div>
      </mat-card>
    </mat-tab>
  </mat-tab-group>
</mat-card>
