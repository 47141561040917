import { Component, OnInit, Inject } from "@angular/core";
import { DataAccessService } from "../core/services/data-access.service";
import { Router } from "@angular/router";
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
} from "@angular/material/legacy-dialog";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [DataAccessService],
})
export class LoginComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private dataAccess: DataAccessService,
    public app: AppComponent
  ) {}

  login: string = "";
  passwd: string = "";
  errorMsg: string = "";
  showPassword: boolean = false;

  ngOnInit() {
    this.dataAccess.getCsrf();
    // if ((this.dataAccess.getLoginData() as any).user_id > 0) {
    //   this.app.logout();
    // }
  }

  mouseDown() {
    this.showPassword = true;
  }

  mouseUp() {
    this.showPassword = false;
  }

  userLogIn() {
    this.dataAccess
      .getJSONPost(
        "login",
        [],
        [
          { k: "login", v: this.login },
          { k: "password", v: this.passwd },
        ]
      )
      .then((data) => {
        if (data.res == 1) {
          this.errorMsg = data.msg;
        } else {
          this.errorMsg = "";

          if (!(data.user_role_id > 0) && data.roles) {
            if (data.roles[0]) {
              data.user_role_id = data.roles[0].id;
            }
          }

          if (!data.gdpr_accepted) {
            const dialogRef = this.dialog.open(GDPRDialog, {
              disableClose: true,
              width: "1200px",
            });

            dialogRef.afterClosed().subscribe(() => {});
          }

          this.dataAccess.setLoginData(data);

          if (data.password_change_required) {
            this.router.navigate(["/settings"]); //redirect to password change
          } else {
            this.router.navigate(["/home"]);
          }
        }
      });
  }

  forgotPassword() {
    this.router.navigate(["/forgotpassword"]);
  }
}

@Component({
  selector: "gdpr-confirmation-dialog",
  templateUrl: "gdpr-confirmation-dialog.html",
  styleUrls: ["./login.component.scss"],
})
export class GDPRDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GDPRDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataAccess: DataAccessService
  ) {}

  ngOnInit() {
    window.scroll(0, 0);
  }

  onAccept(): void {
    this.dataAccess.getJSON("confirm_gdpr", [], []).then((data) => {
      this.dialogRef.close();
    });
  }
}
