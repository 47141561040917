<h1 mat-dialog-title>Edit Factory Categories</h1>
<form class="example-form">
    <div mat-dialog-content style="padding-top: 4px; padding-bottom: 4px;">
      <mat-accordion>
        <mat-expansion-panel disabled [(expanded)]="expandedCategories">
          <mat-expansion-panel-header>
            <mat-panel-title *ngIf="!selectedCategory">Categories</mat-panel-title>
            <mat-panel-title *ngIf="selectedCategory">Select users for category and click 'Save users'</mat-panel-title>
          </mat-expansion-panel-header>
          <table mat-table [dataSource]="categories" class="categories-table">
            <tr mat-header-row *matHeaderRowDef="['Label', 'Checkbox', 'IsMain']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['Label', 'Checkbox', 'IsMain'];"></tr>
            <ng-container matColumnDef="Label">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" (click)="labelClicked($event, element.id)"> {{element.name}} </td>
            </ng-container>
            <ng-container matColumnDef="Checkbox">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox
                  [(ngModel)]="categoriesState[element.id]"
                  [ngModelOptions]="{standalone: true}"
                  (click)="categoryClicked($event, element.id)"
                  (change)="categoryChange($event, element.id)"
                ></mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="IsMain">
              <th mat-header-cell *matHeaderCellDef>Main category</th>
              <td mat-cell *matCellDef="let element">
                <mat-radio-group [(ngModel)]="categoryMain" [ngModelOptions]="{standalone: true}">
                  <mat-radio-button [value]="element.id" [disabled]="!categoriesState[element.id]"></mat-radio-button>
                </mat-radio-group>
              </td>
            </ng-container>
          </table>
        </mat-expansion-panel>
        <mat-expansion-panel disabled [(expanded)]="expandedUsers">
          <mat-expansion-panel-header>
            <mat-panel-title *ngIf="!selectedCategory">Enable/click category to edit users</mat-panel-title>
            <mat-panel-title *ngIf="selectedCategory">Users for category "{{getSelectedCategoryName()}}"</mat-panel-title>
          </mat-expansion-panel-header>
          <div style="display: flex; flex-direction: column;">
              <mat-form-field style="width: 100%; flex: 1 1 auto;" *ngIf="usersBDM && selectedCategory">
                  <mat-label>BDM</mat-label>
                  <mat-select [(ngModel)]='bizTeam[selectedCategory].bdm_user_id' [ngModelOptions]="{standalone: true}" required>
                      <mat-option *ngFor="let user of usersBDM[selectedCategory]" [value]="user.id">{{user.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <mat-form-field style="width: 100%; flex: 1 1 auto;" *ngIf="usersBD && selectedCategory">
                  <mat-label>BD</mat-label>
                  <mat-select [(ngModel)]='bizTeam[selectedCategory].bd_user_id' [ngModelOptions]="{standalone: true}" required>
                      <mat-option *ngFor="let user of usersBD[selectedCategory]" [value]="user.id">{{user.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <mat-form-field style="width: 100%; flex: 1 1 auto;" *ngIf="usersSP && selectedCategory">
                  <mat-label>SP</mat-label>
                  <mat-select [(ngModel)]='bizTeam[selectedCategory].sp_user_id' [ngModelOptions]="{standalone: true}" required>
                      <mat-option *ngFor="let user of usersSP[selectedCategory]" [value]="user.id">{{user.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <mat-form-field style="width: 100%; flex: 1 1 auto;" *ngIf="usersPNE && selectedCategory">
                  <mat-label>PNE</mat-label>
                  <mat-select [(ngModel)]='bizTeam[selectedCategory].pne_user_id' [ngModelOptions]="{standalone: true}" required>
                      <mat-option *ngFor="let user of usersPNE[selectedCategory]" [value]="user.id">{{user.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
            </div>
            <button mat-button (click)="onSaveUsersClick()">Save users</button>
            <button mat-button (click)="onCancelUsersClick()">Cancel</button>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
</form>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>Ok</button>
</div>
